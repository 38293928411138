// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Axios from "axios";
import * as ReactOnRails from "rescript-react-on-rails/src/ReactOnRails.bs.js";
import * as Json$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json.bs.js";
import * as Json_Decode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Decode.bs.js";

async function storeComment(comment) {
  await Axios.post("comments.json", {
        author: comment.author,
        text: comment.text
      }, {
        responseType: "json",
        headers: {
          "X-CSRF-Token": ReactOnRails.authenticityToken(undefined),
          "X-Requested-With": "XMLHttpRequest"
        }
      });
}

var Create = {
  storeComment: storeComment
};

async function fetchComments(param) {
  var response = await fetch("comments.json");
  var jsonRes = await response.json();
  var jsonComment = Json_Decode$JsonCombinators.object(function (field) {
        return {
                author: field.required("author", Json_Decode$JsonCombinators.string),
                text: field.required("text", Json_Decode$JsonCombinators.string),
                id: field.required("id", Json_Decode$JsonCombinators.$$int)
              };
      });
  var jsonComments = Json_Decode$JsonCombinators.object(function (field) {
        return {
                comments: field.required("comments", Json_Decode$JsonCombinators.array(jsonComment))
              };
      });
  var decodedRes = Json$JsonCombinators.decode(jsonRes, jsonComments);
  if (decodedRes.TAG === /* Ok */0) {
    return {
            TAG: /* Ok */0,
            _0: decodedRes._0.comments
          };
  } else {
    return {
            TAG: /* Error */1,
            _0: decodedRes._0
          };
  }
}

var Fetch = {
  fetchComments: fetchComments
};

export {
  Create ,
  Fetch ,
}
/* axios Not a pure module */
