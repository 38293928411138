// Generated by ReScript, PLEASE EDIT WITH CARE

import * as JsxRuntime from "react/jsx-runtime";

function HorizontalForm(props) {
  return JsxRuntime.jsxs("form", {
              children: [
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx("label", {
                              children: "Name",
                              className: "w-full lg:w-2/12 lg:text-end shrink-0"
                            }),
                        JsxRuntime.jsx("input", {
                              className: "px-3 py-1 leading-4 border border-gray-300 rounded w-full",
                              id: "comment_author",
                              name: "comment_author",
                              placeholder: "Your Name",
                              type: "text",
                              value: props.author,
                              onChange: props.handleAuthorChange
                            })
                      ],
                      className: "flex flex-col gap-0 items-center lg:gap-4 lg:flex-row"
                    }),
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx("label", {
                              children: "Text",
                              className: "w-full lg:w-2/12 lg:text-end shrink-0"
                            }),
                        JsxRuntime.jsx("input", {
                              className: "px-3 py-1 leading-4 border border-gray-300 rounded w-full",
                              id: "comment_text",
                              name: "comment_text",
                              placeholder: "Say something using markdown...",
                              type: "text",
                              value: props.text,
                              onChange: props.handleTextChange
                            })
                      ],
                      className: "flex flex-col gap-0 items-center lg:gap-4 lg:flex-row"
                    }),
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx("div", {
                              className: "hidden lg:block lg:w-2/12 grow-0"
                            }),
                        JsxRuntime.jsx("input", {
                              className: "self-start px-3 py-1 font-semibold border-0 rounded text-sky-50 bg-sky-600 hover:bg-sky-800",
                              type: "submit",
                              value: "Post"
                            })
                      ],
                      className: "flex flex-col gap-0 lg:gap-4 lg:flex-row"
                    })
              ],
              className: "form-horizontal flex flex-col gap-4",
              disabled: props.disabled,
              onSubmit: props.handleSubmit
            });
}

var make = HorizontalForm;

export {
  make ,
}
/* react/jsx-runtime Not a pure module */
