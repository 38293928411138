// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Actions from "../Actions/Actions.bs.js";
import * as AlertError from "../CommentList/AlertError/AlertError.bs.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as InlineForm from "./forms/InlineForm.bs.js";
import * as StackedFrom from "./forms/StackedFrom.bs.js";
import * as HorizontalForm from "./forms/HorizontalForm.bs.js";
import * as JsxRuntime from "react/jsx-runtime";

function reducer(state, action) {
  if (typeof action === "number") {
    switch (action) {
      case /* SetSavingError */0 :
          return {
                  author: state.author,
                  text: state.text,
                  form: state.form,
                  savingStatus: /* Error */2
                };
      case /* ClearSavingError */1 :
          return {
                  author: state.author,
                  text: state.text,
                  form: state.form,
                  savingStatus: /* Idle */0
                };
      case /* SetStoreStatusSaving */2 :
          return {
                  author: state.author,
                  text: state.text,
                  form: state.form,
                  savingStatus: /* Saving */1
                };
      
    }
  } else {
    switch (action.TAG | 0) {
      case /* SetAuthor */0 :
          return {
                  author: action._0,
                  text: state.text,
                  form: state.form,
                  savingStatus: state.savingStatus
                };
      case /* SetText */1 :
          return {
                  author: state.author,
                  text: action._0,
                  form: state.form,
                  savingStatus: state.savingStatus
                };
      case /* SetFormType */2 :
          return {
                  author: state.author,
                  text: state.text,
                  form: action._0,
                  savingStatus: state.savingStatus
                };
      
    }
  }
}

function CommentForm(props) {
  var fetchData = props.fetchData;
  var match = React.useReducer(reducer, {
        author: "",
        text: "",
        form: /* Horizontal */0,
        savingStatus: /* Idle */0
      });
  var dispatch = match[1];
  var state = match[0];
  var disabled = React.useMemo((function () {
          var match = state.savingStatus;
          return match === 1;
        }), [state.savingStatus]);
  var handleAuthorChange = function ($$event) {
    var value = $$event.currentTarget.value;
    Curry._1(dispatch, {
          TAG: /* SetAuthor */0,
          _0: value
        });
  };
  var handleTextChange = function ($$event) {
    var value = $$event.currentTarget.value;
    Curry._1(dispatch, {
          TAG: /* SetText */1,
          _0: value
        });
  };
  var handleSubmit = function ($$event) {
    $$event.preventDefault();
    var newComment = {
      author: state.author,
      text: state.text
    };
    Curry._1(dispatch, /* SetStoreStatusSaving */2);
    var saveAndFetchComments = async function (param) {
      try {
        await Actions.Create.storeComment(newComment);
        Curry._1(dispatch, /* ClearSavingError */1);
        return await Curry._1(fetchData, undefined);
      }
      catch (exn){
        return Curry._1(dispatch, /* SetSavingError */0);
      }
    };
    saveAndFetchComments(undefined);
  };
  var forms = [
    {
      formName: "Horizontal Form",
      formType: /* Horizontal */0
    },
    {
      formName: "Inline Form",
      formType: /* Inline */1
    },
    {
      formName: "Stacked Form",
      formType: /* Stacked */2
    }
  ];
  var match$1 = state.form;
  var tmp;
  switch (match$1) {
    case /* Horizontal */0 :
        tmp = JsxRuntime.jsx(HorizontalForm.make, {
              author: state.author,
              handleAuthorChange: handleAuthorChange,
              text: state.text,
              handleTextChange: handleTextChange,
              handleSubmit: handleSubmit,
              disabled: disabled
            });
        break;
    case /* Inline */1 :
        tmp = JsxRuntime.jsx(InlineForm.make, {
              author: state.author,
              handleAuthorChange: handleAuthorChange,
              text: state.text,
              handleTextChange: handleTextChange,
              handleSubmit: handleSubmit,
              disabled: disabled
            });
        break;
    case /* Stacked */2 :
        tmp = JsxRuntime.jsx(StackedFrom.make, {
              author: state.author,
              handleAuthorChange: handleAuthorChange,
              text: state.text,
              handleTextChange: handleTextChange,
              handleSubmit: handleSubmit,
              disabled: disabled
            });
        break;
    
  }
  var match$2 = state.savingStatus;
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx("div", {
                      children: Belt_Array.map(forms, (function (form) {
                              return JsxRuntime.jsx("button", {
                                          children: form.formName,
                                          className: "px-6 py-2 font-semibold border-0 rounded " + (
                                            state.form === form.formType ? "text-sky-50 bg-sky-600" : "text-sky-600 hover:bg-gray-100"
                                          ) + "",
                                          onClick: (function ($$event) {
                                              Curry._1(dispatch, {
                                                    TAG: /* SetFormType */2,
                                                    _0: form.formType
                                                  });
                                            })
                                        }, "form_" + form.formName + "");
                            })),
                      className: "flex gap-1 not-prose"
                    }),
                JsxRuntime.jsx("hr", {}),
                tmp,
                match$2 >= 2 ? JsxRuntime.jsx(AlertError.make, {
                        errorMsg: "Can't save the comment!"
                      }) : null
              ]
            });
}

var make = CommentForm;

export {
  reducer ,
  make ,
}
/* react Not a pure module */
