// Generated by ReScript, PLEASE EDIT WITH CARE

import * as JsxRuntime from "react/jsx-runtime";

function StackedFrom(props) {
  var handleSubmit = props.handleSubmit;
  return JsxRuntime.jsxs("form", {
              children: [
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx("label", {
                              children: "Name",
                              className: "w-full"
                            }),
                        JsxRuntime.jsx("input", {
                              className: "px-3 py-1 leading-4 border border-gray-300 rounded w-full",
                              id: "comment_author",
                              name: "comment_author",
                              placeholder: "Your Name",
                              type: "text",
                              value: props.author,
                              onChange: props.handleAuthorChange
                            })
                      ],
                      className: "flex flex-col gap-0"
                    }),
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx("label", {
                              children: "Name",
                              className: "w-full"
                            }),
                        JsxRuntime.jsx("input", {
                              className: "px-3 py-1 leading-4 border border-gray-300 rounded w-full",
                              id: "comment_text",
                              name: "comment_text",
                              placeholder: "Say something using markdown...",
                              type: "text",
                              value: props.text,
                              onChange: props.handleTextChange
                            })
                      ],
                      className: "flex flex-col gap-0"
                    }),
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsx("input", {
                            className: "self-start px-3 py-1 font-semibold border-0 rounded text-sky-50 bg-sky-600 hover:bg-sky-800",
                            type: "submit",
                            value: "Post",
                            onSubmit: handleSubmit
                          }),
                      className: "flex flex-col gap-0"
                    })
              ],
              className: "flex flex-col gap-4",
              disabled: props.disabled,
              onSubmit: handleSubmit
            });
}

var make = StackedFrom;

export {
  make ,
}
/* react/jsx-runtime Not a pure module */
