// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Header from "./Header/Header.bs.js";
import * as Actions from "./Actions/Actions.bs.js";
import * as AlertError from "./CommentList/AlertError/AlertError.bs.js";
import * as ActionCable from "./bindings/ActionCable.bs.js";
import * as CommentForm from "./CommentForm/CommentForm.bs.js";
import * as CommentList from "./CommentList/CommentList.bs.js";
import * as JsxRuntime from "react/jsx-runtime";

function reducer(param, action) {
  if (action) {
    return {
            commentsFetchStatus: /* CommentsFetched */{
              _0: action._0
            }
          };
  } else {
    return {
            commentsFetchStatus: /* FetchError */0
          };
  }
}

function ReScriptShow$default(props) {
  var match = React.useReducer(reducer, {
        commentsFetchStatus: /* CommentsFetched */{
          _0: []
        }
      });
  var dispatch = match[1];
  var fetchData = async function (param) {
    var comments = await Actions.Fetch.fetchComments(undefined);
    if (comments.TAG === /* Ok */0) {
      return Curry._1(dispatch, /* SetComments */{
                  _0: comments._0
                });
    } else {
      return Curry._1(dispatch, /* SetFetchError */0);
    }
  };
  var subscribeToCommentsChannel = function (param) {
    return ActionCable.subscribeConsumer("CommentsChannel", {
                connected: (function (param) {
                    console.log("Connected");
                  }),
                received: (function (data) {
                    Curry._1(dispatch, /* SetComments */{
                          _0: [data]
                        });
                  }),
                disconnected: (function (param) {
                    console.log("Disconnected");
                  })
              });
  };
  React.useEffect((function () {
          var scription = subscribeToCommentsChannel(undefined);
          return (function (param) {
                    scription.unsubscribe();
                  });
        }), []);
  React.useEffect((function () {
          fetchData(undefined);
        }), []);
  var comments = match[0].commentsFetchStatus;
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsxs("h2", {
                      children: [
                        "Rescript + Rails Backend (with ",
                        JsxRuntime.jsx("a", {
                              children: "react_on_rails gem",
                              href: "https://github.com/shakacode/react_on_rails"
                            }),
                        ")"
                      ]
                    }),
                JsxRuntime.jsx(Header.make, {}),
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx("h2", {
                              children: "Comments"
                            }),
                        JsxRuntime.jsxs("ul", {
                              children: [
                                JsxRuntime.jsx("li", {
                                      children: "Text supports Github Flavored Markdown."
                                    }),
                                JsxRuntime.jsx("li", {
                                      children: "Comments older than 24 hours are deleted."
                                    }),
                                JsxRuntime.jsx("li", {
                                      children: "Name is preserved. Text is reset, between submits"
                                    }),
                                JsxRuntime.jsx("li", {
                                      children: "To see Action Cable instantly update two browsers, open two browsers and submit a comment!"
                                    })
                              ]
                            }),
                        JsxRuntime.jsx(CommentForm.make, {
                              fetchData: fetchData
                            }),
                        comments ? JsxRuntime.jsx(CommentList.make, {
                                comments: comments._0
                              }) : JsxRuntime.jsx(AlertError.make, {
                                errorMsg: "Can't fetch the comments!"
                              })
                      ],
                      className: "prose max-w-none prose-a:text-sky-700 prose-li:my-0"
                    })
              ]
            });
}

var $$default = ReScriptShow$default;

export {
  reducer ,
  $$default ,
  $$default as default,
}
/* react Not a pure module */
