// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";

function subscribeConsumer(channnelName, subscriprtionCallbacks) {
  var consumer = Curry._1(ActionCable.createConsumer, undefined);
  return consumer.subscriptions.create(channnelName, subscriprtionCallbacks);
}

export {
  subscribeConsumer ,
}
/* No side effect */
