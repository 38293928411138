// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Marked from "marked";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as ReactTransitionGroup from "react-transition-group";

function $$Comment(props) {
  var comment = props.comment;
  var rawMarkup = Marked.marked(comment.text, {
        gfm: true
      });
  var innerHTML = {
    __html: rawMarkup
  };
  var nodeRef = React.useRef(null);
  return JsxRuntime.jsx(ReactTransitionGroup.CSSTransition, {
              children: JsxRuntime.jsxs("div", {
                    children: [
                      JsxRuntime.jsx("h2", {
                            children: comment.author,
                            className: "js-comment-author text-blue-800"
                          }),
                      JsxRuntime.jsx("span", {
                            className: "js-comment-text",
                            dangerouslySetInnerHTML: Caml_option.some(innerHTML)
                          })
                    ],
                    ref: Caml_option.some(nodeRef)
                  }),
              timeout: 500,
              nodeRef: nodeRef,
              classNames: props.cssTransitionGroupClassNames
            }, "component_" + String(comment.id));
}

var make = $$Comment;

export {
  make ,
}
/* react Not a pure module */
