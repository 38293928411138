// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as JsxRuntime from "react/jsx-runtime";
import * as ReactTransitionGroup from "react-transition-group";
import ReScriptShowModuleScss from "../../ReScriptShow.module.scss";

var css = ReScriptShowModuleScss;

function AlertError(props) {
  var nodeRef = React.useRef(null);
  var cssTransitionGroupClassNames_enter = css.elementEnter;
  var cssTransitionGroupClassNames_enterActive = css.elementEnterActive;
  var cssTransitionGroupClassNames_exit = css.elementLeave;
  var cssTransitionGroupClassNames_exitActive = css.elementLeaveActive;
  var cssTransitionGroupClassNames = {
    enter: cssTransitionGroupClassNames_enter,
    enterActive: cssTransitionGroupClassNames_enterActive,
    exit: cssTransitionGroupClassNames_exit,
    exitActive: cssTransitionGroupClassNames_exitActive
  };
  return JsxRuntime.jsx(ReactTransitionGroup.CSSTransition, {
              children: JsxRuntime.jsx("div", {
                    children: JsxRuntime.jsx("strong", {
                          children: props.errorMsg
                        }),
                    className: "bg-pink-100 p-4 mb-4 border border-pink-200 rounded text-red-800 prose-strong:text-red-800 prose-ul:my-1"
                  }),
              timeout: 500,
              nodeRef: nodeRef,
              classNames: cssTransitionGroupClassNames
            }, "commentFetchError");
}

var make = AlertError;

export {
  css ,
  make ,
}
/* css Not a pure module */
